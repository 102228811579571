/*
 * * File: custom.scss
 * Path: /css/custom.scss
 * File Created: Wednesday, 6th March 2019 1:55:03 pm
 * Author: sammosna (samuele@hgblu.com)
 * -----
 * Last Modified: Wednesday, 6th March 2019 5:53:42 pm
 * Modified By: sammosna (samuele@hgblu.com>)
 * -----
 * Copyright 2017 - 2019 Hg blu, Hg blu
 */

:root {
  --main-nav-h: 70px;
}

body * {
  letter-spacing: 0px !important;
}

.noDots #slide-numbers {
  display: none !important;
}

/* rosso #a6004a
blu #0079a2 */

p,
a {
  font-size: 1rem;
  line-height: 1.5em;
}

ul {
  font-size: 1rem;
}

.breadcrumb {
  background: transparent;
  // padding-top: 5rem;
  margin-bottom: 0px;
  padding-bottom: 0px;

  .breadcrumb-item {
    color: #999;
    font-size: 0.8rem;

    a {
      color: #999;
      font-size: 0.8rem;
    }
  }
}

.breadcrumb-item+.breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.25rem;
  padding-left: 0.25rem;
  color: #999;
  content: "/";
}

.hp-title {
  font-size: 4rem;
  text-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

  br {
    line-height: 8rem;
  }
}

#mainNav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  height: var(--main-nav-h);
  z-index: 1000;

  .nav-bg {
    padding-left: 70px;
    position: absolute;
    z-index: 1;

    .nav-bg-block {
      background-color: #fff;
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .nav-logo {
    height: 100%;
    padding: 10px;
    padding-left: 70px;
    display: inline-block;
    position: relative;
    z-index: 10;

    img {
      height: 100%;
    }
  }

  #menu-toggle {
    position: absolute;
    right: 0;
    top: 0;
    height: var(--main-nav-h);
    min-width: var(--main-nav-h);
    display: table;
    line-height: 100%;
    background-color: #82be46;
    border: 3px solid #82be46;
    color: #fff;
    text-align: center;

    &:hover {
      cursor: pointer;
      /* background-color: #fff;
      border: 3px solid #1B1D1B;
      color: #1B1D1B; */
    }

    .menu-info {
      position: absolute;
      left: -5rem;
      width: 5rem;
      text-align: right;
      color: #1b1d1b;
      display: block;
      height: 100%;
      vertical-align: middle;
      display: table;
      padding-right: 1rem;

      >span {
        display: table-cell;
        vertical-align: middle;
      }
    }

    i {
      display: table-cell;
      line-height: 100%;
      vertical-align: middle;
      font-size: 1.3em;
      transition-duration: 0.3s;
    }

    &:hover i {
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }
  }

  #search {
    $offset: 20rem;

    @media screen and (max-width: 731px) {
      $offset: 15rem;
    }

    position: absolute;
    right: 0;
    top: 70px;
    height: 70px;
    width: calc(70px + #{$offset});
    transform: translateX($offset);
    transition-duration: 0.6s;

    .search-button {
      width: 70px;
      height: 70px;
      background-color: #fff;
      display: table;
      text-align: center;
      float: left;
      cursor: pointer;

      i {
        display: table-cell;
        line-height: 100%;
        vertical-align: middle;
        font-size: 1.3em;
        transition-duration: 0.3s;

        &.fa-times {
          display: none;
        }
      }
    }

    .search-field {
      height: 70px;
      background-color: #fff;
      float: left;
      width: $offset;

      input {
        height: 40px;
        margin-top: 15px;
        border: 0px;
        outline: none;
        width: 90%;
        border-bottom: 1px solid #000;
        border-radius: 0px;
      }
    }

    &.active {
      transform: translateX(0px);

      .search-button {
        i.fa-times {
          display: table-cell;
        }

        i.fa-search {
          display: none;
        }
      }
    }
  }
}

#mainMenu {
  font-family: "Montserrat", sans-serif;
  background-color: #82be46;
  position: fixed;
  top: 0;
  height: 100%;
  right: -100%;
  padding: 2.7em;
  color: #fff;
  -webkit-transition-duration: 0.9s;
  transition-duration: 0.9s;

  @media screen and (max-height: 700px) {
    padding: 1rem;
    display: table;
    height: 100%;
  }

  &.opened {
    right: 0;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }

  #menu-close {
    position: absolute;
    height: var(--main-nav-h);
    display: table;
    line-height: 100%;
    top: 0;
    min-width: var(--main-nav-h);
    left: calc(0px - var(--main-nav-h));
    background: #82be46;
    text-align: center;

    &:hover {
      cursor: pointer;
    }

    i {
      display: table-cell;
      vertical-align: middle;
      font-size: 1.3em;
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;
    }

    &:hover i {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  ul {
    list-style: none;
    padding-left: 0.5em;

    &.main-navigation {
      @media screen and (max-height: 700px) {
        //commentanto perchè faceva saltare il menu da mobile su determinate altezze
        // display: table-cell;
        // vertical-align: middle;
      }
    }

    >li {
      font-size: 1.2rem;
      margin-bottom: 1em;

      @media screen and (max-height: 700px) {
        margin-bottom: 0px;
      }

      >ul>li {
        font-size: 1rem;
        margin-bottom: 0;
      }
    }
  }

  a {
    color: #fff;
    text-decoration: none;
    transition-duration: 0.3s;
    display: inline-block;

    &::before {
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      content: "\f054";
      margin-right: 0.5rem;
      opacity: 0;
    }

    &:hover {
      transform: translateX(1rem);

      &::before {
        opacity: 1;
        transition-duration: 0.3s;
      }
    }
  }
}

.img-bg {
  color: #fff;
  background-position: center center;
}

.img-bg-video::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f144";
  font-size: 10rem;
  line-height: 20rem;
  /* text-align: center; */
  /* margin: auto; */
  margin-top: calc(50% - 5rem);
  margin-left: calc(50% - 5rem);
  transform: translateY(-50%);
  cursor: pointer;

  @media screen and (max-width: 768px) {
    transform: translateY(0);
  }
}

.pane-container .pane {
  padding-right: 0;
  padding-top: var(--main-nav-h);
  padding-left: var(--main-nav-h);
  background-color: #fff;

  &.sub-pane {
    p {
      margin-bottom: 2em;
    }

    h3:not(.home-h3) {
      color: #82be46;
      font-size: 3.3rem;
      margin-bottom: 0rem;
    }
  }

  .content-container {
    background-color: #fff;
  }

  .goto-nextSection {
    color: #82be46;
    text-transform: uppercase;
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 50%;
    margin-bottom: 4rem;
    line-height: 1em;

    a {
      color: inherit;
      text-decoration: none !important;
    }

    i {
      font-size: 2em;
    }
  }
}

#slide-numbers {
  left: 0px;

  >ul {
    padding-left: 30px;
  }
}

h1,
h2,
h3 {
  font-family: "Merriweather", serif;
  text-transform: none;
}

h1 {
  font-size: 7rem;
}

h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2.5rem;

  @media screen and (max-height: 700px) {
    font-size: 1.9rem;
  }

  @media screen and (min-width: 768px) and (max-width: 1200px) {
    font-size: 2.1rem;
  }

  &::before {
    content: "";
    width: 2em;
    height: 2em;
    position: absolute;
    top: -0.5em;
    left: 0.5em;
    background-image: url("../imgs/fingerprint.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: -1;
    opacity: 0.5;
  }
}

h5.occhiello {
  margin-top: 1rem;
  margin-bottom: 2rem;
  font-weight: bold;
}

.right-col {
  background-image: url("../imgs/pattern.svg");
}

.wrapper {
  // @media screen and (max-height: 700px) {
  //   padding-bottom: 0px !important;
  // }
  transform: translateY(0);

  &:not(.wrapper-home):not(.full) {
    width: 75%;
    margin-left: 0;
    padding-top: 0px;
  }

  h3:not(.home-h3) {
    /* width: 75%; */
    /* margin-bottom: 5rem; */
    font-weight: 800;
    color: #82be46;
  }
}

.servizio {
  font-family: "Montserrat", sans-serif;
  position: absolute;
  right: 1em;
  top: 3em;
  text-align: right;
  font-size: 2rem;

  span {
    font-family: "Merriweather", serif;
    font-size: 3em;
    line-height: 5rem;
    display: block;
    text-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }
}

.servizi-lista {
  list-style: none;
  padding: 0;
  margin-bottom: 5rem;
  margin-top: 5rem;

  @media screen and (max-height: 700px) {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  li {
    margin: 1.5em 0;
    color: #82be46;

    // > i {
    //   color: #007ba6;
    // }

    a {
      font-family: "Montserrat", sans-serif;
      font-size: 1.5rem;
      text-transform: uppercase;
      color: #82be46;

      @media screen and (max-height: 700px) {
        font-size: 1.2rem;
      }
    }
  }

  .lista-link {
    text-transform: none !important;
  }
}

.footer {
  z-index: 500;
  position: relative;
  // padding-left: 70px;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  font-size: 0.9rem;

  .content-container {
    font-family: "Montserrat", sans-serif;
    color: #fff;
    font-size: 0.9rem;
    // background-image: url("../imgs/pattern.svg");
    background-color: #82be46;
    padding-bottom: 0.5rem;
  }

  .fp-tableCell {
    padding-top: 70px;
  }

  .content-container {
    padding-top: 4rem;
  }

  p {
    margin-bottom: 2em;
  }

  ul {
    list-style: none;
    padding-left: 1em;

    li a {
      color: #fff;
    }
  }
}

.img-vert-slider {
  overflow-y: hidden;
  /* background-color: #ddd; */
  /* height: calc(100vh - 70px); */
  height: 100vh;
  /* border: 1px solid red; */
  overflow-y: hidden;
  /* width: 400px;
    height: 100px; */
  white-space: nowrap;
  font-size: 0;

  /* white-space fix*/
  >.vert-slider-container {
    >div {
      height: 500px;
      background-color: #ff1183;
      border-bottom: 1px solid #fff;
    }

    img {
      width: 100%;
      display: block;
    }
  }
}

.pane .wrapper:not(.no-max-w) {
  max-width: 48rem;
  margin-left: 0px !important;
}

.wrapper.full {
  max-width: none !important;
}

.slick-gallery-elem {
  height: 70vh;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.sg-prev,
.sg-next {
  background-color: #000;
  color: #fff;
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  position: absolute;
  top: 50%;
  margin-top: -1.5rem;
}

.sg-prev {
  left: 0;
}

.sg-next {
  right: 0;
}

.btn.btn-primary {
  border-radius: 0px;
  border: 1px solid #999;
  background: transparent;
  color: #82be46;
  text-transform: uppercase;
  font-weight: bold;

  &:hover {
    background-color: #82be46;
    border-color: #82be46;
    color: #fff;
  }

  &:focus {
    outline: none;
  }
}

.slide-numbers {}

#slide-numbers ul .paginate {
  cursor: pointer;
  border-radius: 50%;
  list-style: none;
  background: #999 !important;
  border-color: transparent;
  border-width: 2px;
  border-style: solid;
  height: 10px;
  width: 10px;
  margin: 5px 0px;

  &.active {
    background: #007ba6 !important;
    border-color: #007ba6 !important;
    height: 14px !important;
    width: 14px !important;
    margin-left: -2px !important;
  }
}

#videoModal {
  .modal-dialog {
    max-width: none;
    width: fit-content;
  }

  .modal-content {
    background: transparent;
    border: none;

    iframe {
      max-width: 90vw;
      max-height: 80vh;
    }
  }

  .modal-toggle {
    position: absolute;
    right: 0;
    top: 0;
    height: var(--main-nav-h);
    min-width: var(--main-nav-h);
    display: table;
    line-height: 100%;
    background-color: #1b1d1b;
    color: #fff;
    text-align: center;
    z-index: 300;

    i {
      display: table-cell;
      line-height: 100%;
      vertical-align: middle;
      font-size: 1.3em;
    }
  }
}

.page ul li {
  font-size: 16px;
}

.footer-menu ul li a {
  font-size: 0.8rem;
}

.footer-credits a {
  font-size: inherit;
  color: #fff;
}

.menu-primary-elem>a {
  text-transform: uppercase;
}

.scroll-downs {
  position: absolute;
  /* top: 0; */
  right: 0;
  bottom: 8rem;
  left: 0;
  margin: auto;
  width: 34px;
  height: 55px;
}

.mousey {
  width: 3px;
  padding: 10px 15px;
  height: 35px;
  border: 3px solid #fff;
  border-radius: 25px;
  opacity: 1;
  box-sizing: content-box;
  box-shadow: inset 0 10px 20px rgba(0, 0, 0, 0.19),
    0 6px 6px rgba(0, 0, 0, 0.23);
}

.scroller {
  width: 4px;
  height: 10px;
  border-radius: 25%;
  background-color: #fff;
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
  animation-iteration-count: infinite;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

@keyframes scroll {
  0% {
    opacity: 0;
  }

  10% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(15px);
    opacity: 0;
  }
}

/**
	fullpage
**/

#fullpage {
  /* transition-duration: 0.3s; */
}

.fp-tableCell {

  .img-bg,
  .content-container {
    height: 100%;
  }
}

#fp-nav {
  z-index: 10;

  &.fp-left {
    left: 22px;
  }

  ul li a span {
    background: #999;
  }
}

.fp-slidesNav ul li a span {
  background: #999;
}

#fp-nav ul li {

  a.active span,
  &:hover a.active span {
    background: #82be46;
  }
}

.fp-slidesNav ul li {

  a.active span,
  &:hover a.active span {
    background: #007ba6;
  }
}

.section {
  padding: 70px 0 0 0px; // era 70 0 0 70
  padding-top: 0px;
  margin-top: 12rem;
  margin-bottom: 12rem;

  @media screen and (max-width: 731px) {
    padding-left: 0px;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
}

@media screen and (max-width: 412px) {
  .section {
    padding: 0px;
  }

  .fp-tableCell {

    .img-bg,
    .content-container {
      height: auto;
    }
  }
}

.goto-nextSection {
  position: absolute;
  bottom: 2rem;
  width: 100%;
  text-align: center;
  font-size: 1.5rem;

  a {
    font-size: inherit;
    color: #82be46;
    font-family: "Merriweather", serif;
  }
}

/*
 * ──────────────────────────────────────────────────── I ──────────
 *   :::::: M O B I L E : :  :   :    :     :        :          :
 * ──────────────────────────────────────────────────────────────
 */

@media screen and (max-width: 731px) {
  body {
    padding-top: 70px;
    /* font-size: 14px; */
  }

  h1 {
    font-size: 3rem;
    margin-top: 100px;
  }

  h2 {
    font-size: 2rem;
  }

  #mainNav .nav-logo {
    padding: 1rem 0 1rem 1rem;
  }

  .scroll-downs {
    display: none;
  }

  .section.pane.sub-pane {
    height: fit-content !important;
  }

  .hp-title {
    margin-top: 4rem;

    span {
      font-size: 2rem;
      line-height: 2.5rem;
      margin: 2rem 0;
      display: block;
    }
  }

  .menu-info {
    display: none !important;
  }

  #mainMenu {
    overflow: auto;
    padding: 0;
    width: 100%;

    #menu-close {
      position: relative;
      left: 80%;
    }

    .nav-image {
      display: none;
    }

    ul.main-navigation {
      padding: 2em;
      // height: 100%;
      // padding-bottom: 30vh;
      overflow-y: auto;
      // V aggiunto per centrare il menu verticalmente V
      height: auto;
      top: calc(50% - 70px);
      transform: translateY(-50%);
      position: relative;
    }
  }

  .pane-container .pane {
    padding-left: 0;
    padding-top: 0;
  }

  .img-bg {
    min-height: 450px;
  }

  .servizio span {
    font-size: 2.3em;
  }

  .wrapper {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    padding: 20px !important;
    top: 0;
    max-width: none;
    width: 100% !important;

    h3 {
      width: 100%;
      font-size: 1.3rem !important;
      margin-bottom: 1rem !important;
    }
  }

  h5.occhiello {
    font-size: 1em !important;
  }

  .servizi-lista li {
    font-size: 1rem;
    margin: 0.7em 0;

    a {
      font-size: 1rem;
    }
  }

  .footer {}

  .footer-info {
    margin-top: 2rem;
    text-align: center;
  }

  .footer-social {
    text-align: center;

    a {
      color: #fff;
      text-decoration: none;
      font-size: 2rem;
    }
  }

  .pane-container .pane .goto-nextSection,
  .goto-nextSection {
    display: none;
  }

  .slick-gallery-elem {
    /* width: 33vw !important;
    height: 100px !important; */
    height: 30vh !important;
  }

  .img-vert-slider {
    height: 200px;
  }

  .staff-text {
    /* position: absolute; */
    /* padding-top: 230px; */
    /* margin-bottom: 100px; */
    margin-top: -230px;
  }
}

/*
 * ──────────────────────────────────────────────────────────────────── I ──────────
 *   :::::: C E R T I F I C A Z I O N I : :  :   :    :     :        :          :
 * ──────────────────────────────────────────────────────────────────────────────
 */

#certificazioni table {
  border: 0px;
  margin-top: 3rem;

  tr {
    border-color: #82be46;

    td {
      border-color: #ff1183;
      display: inline-block;
      padding: 1rem 0;

      &:first-child {
        width: 130px;
        text-align: center;
      }
    }
  }

  img {
    max-width: 100px;
    height: auto;
    max-height: 70px;
  }
}

#certificazioni-dl {
  height: fit-content !important;

  .fp-tableCell,
  .content-container {
    height: fit-content !important;
  }

  font-size: 0.9rem;

  a {
    color: #82be46;
  }
}

.cert-dl-cont>div {
  display: table;
  height: 100%;

  >div {
    display: table-cell;
    vertical-align: middle;
  }
}

#search-page {
  .page-title {
    margin-top: 70px;
  }

  .search-result {
    color: #000 !important;
    display: block;
    padding: 1rem;
    height: 100%;

    &:hover {
      text-decoration: none;
      background-color: darken(#fff, 10);
    }
  }
}

// CODICE LUCA
.h6 {
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
}

h3 {
  font-family: "Montserrat", sans-serif !important;
}

.wrapper-certifications {
  .col-md-9 {
    p {
      font-family: "Montserrrat", sans-serif;
    }

    .h1 {
      display: block;
    }
  }

  .col-md-3 {
    img {
      width: 100%;
      height: auto;
    }
  }
}

.bg-certificazioni {
  width: 100%;
  position: fixed;
  z-index: 0;
  height: 100%;
  opacity: .1;
  background-repeat: no-repeat;

  &.left {
    left: -12%;
    top: -10%;

    @media screen and (max-width: 992px) {
      display: none;
    }
  }

  &.right {
    right: -70%;
  }
}

.certificazioni-home {

  justify-content: space-between;

  @media screen and (max-width: 768px) {
    justify-content: center;
  }

  .in-evidenza {

    position: relative;
    border-top: 1rem lightgray solid;
    border-bottom: 1rem lightgray solid;
    border-left: .75rem lightgray solid;
    border-right: .75rem lightgray solid;

    &::after {
      width: .75rem;
      right: -.75rem;
    }

    &::before {
      width: .75rem;
      left: -.75rem;
    }
  }
}

.in-evidenza {

  position: relative;
  border-top: 3rem lightgray solid;
  border-bottom: 3rem lightgray solid;
  border-left: 2.5rem lightgray solid;
  border-right: 2.5rem lightgray solid;

  @media screen and (max-width: 768px) {
    border-top-width: 1rem;
    border-bottom-width: 1rem;
    border-left-width: .75rem;
    border-right-width: .75rem;
  }

  h3 {
    font-family: "Montserrat", sans-serif;
    font-weight: normal;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  &:hover {
    text-decoration: none;
    border-color: rgba($color: #82be46, $alpha: .5);
  }

  &::after {
    content: "";
    width: 2.5rem;
    height: 66%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -2.5rem;
    background-color: #fff;

    @media screen and (max-width: 731px) {
      right: -1.5rem;
      width: 1.5rem;
    }
  }

  &::before {
    content: "";
    width: 2.5rem;
    height: 66%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -2.5rem;
    background-color: #fff;

    @media screen and (max-width: 731px) {
      left: -1.5rem;
      width: 1.5rem;
    }
  }
}

.certificazioni-gallery {

  .slick-track {
    display: flex;

    .certificazioni-gallery-elem {
      height: auto;
      display: flex;

      a img {
        margin: auto;
      }
    }
  }
}

.home-hero-gallery-elem {
  img {
    object-fit: cover;

    @media screen and (max-width: 731px) {
      object-fit: contain;
    }
  }
}

.section.hero {
  height: calc(100vh - 70px);
  margin-top: 70px;

  @media screen and (max-width: 768px) {
    height: 100%;
  }
}

.fingerprint {
  &::after {
    content: "";
    width: 2em;
    height: 2em;
    position: absolute;
    top: -.5em;
    background-image: url(../imgs/impronta-verde.png);
    background-position: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: -1;

    @media screen and (max-width: 992px) {
      display: none;
    }
  }
}

.slick-list,
.slick-track {
  height: 100%;
}

.home-h3 {
  text-decoration: none;
}

.box {
  @media screen and (max-width: 768px) {
    margin-top: 3rem;
    margin-bottom: 3rem;

  }

  a {
    text-decoration: none;
  }
}

.gallery-servizi {
  img {
    height: 128px;
    object-fit: cover;

    &:hover {
      cursor: pointer;
    }
  }
}

.border-primary {
  border-color: #82be46 !important;
}

.slick-lightbox-close {
  top: 5vh;
  right: 16vw;
  transform: scale(200%);
}

.h1 {
  @media screen and (max-width: 768px) {
    font-size: 1.8rem;
  }
}

.interna-blocchi {
  .wrapper {
    @media screen and (max-width: 1200px) {
      top: 1% !important;
    }
  }
}

.chi-siamo,
.staff-text {
  .wrapper {
    transform: translateY(-50%);

    @media screen and (max-width: 768px) {
      transform: translateY(2em);
    }
  }
}

.w-xl-50 {
  @media screen and (min-width: 1200px) {
    width: 50%;
  }
}

.wpforms-form div.wpforms-submit-container button[type=submit].wpforms-submit {
  background-color: #82be46;
  color: white;
  border: 1px solid #82be46;
  transition: .2s;

  &:hover {
    background-color: white;
    color: #82be46;
    border: 1px solid #82be46;
  }
}

label.wpforms-field-label-inline {
  color: black;
}

.contacts-map-bg {
  @media screen and (max-width: 731px) {
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.scroll-down {
  position: absolute;
  left: 50%;
  transform: translatex(-50%);
  top: 83%;
  animation-name: jump;
  animation-duration: .5s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-play-state: running;
  z-index: 1;
  display: inline-block;

  @media screen and (max-width: 731px) {
    display: none !important;
  }
}

@keyframes jump {
  from {
    top: 83%
  }

  to {
    top: 84%;
  }
}

.menu-lang {
  position: absolute;
  right: 10rem;
  top: 1.66rem;
}

.remove-before {
  &::before {
    display: none !important;
  }
}